import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useStores } from 'doc-mate-store/lib/hooks';
import { OrderLeg } from 'doc-mate-store/lib/models/OrderLeg';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import LoadStatusTimelineItem from './LoadStatusTimelineItem';

type Props = { firstLoad: OrderLeg; lastLoad: OrderLeg };

const LoadStatusTimelineMultiLeg: React.FC<Props> = ({
  firstLoad,
  lastLoad,
}) => {
  const classes = useStyles();
  const { rootStore } = useStores();

  const { me } = rootStore;
  const user = me ? me.maybeCurrent : undefined;
  const isDispatcher = user && user.isDispatcher;
  const isBroker = user && user.isBroker;

  const driverPosition = !!lastLoad.currentGPSPosition
    ? lastLoad.currentGPSPosition
    : firstLoad.currentGPSPosition;

  const now = moment();
  const timestamp =
    driverPosition &&
    driverPosition.timestamp &&
    moment(driverPosition.timestamp);
  const timestampDiff = now.diff(timestamp, 'minutes');

  const noUpdateTooltip =
    "ETA Might change as driver's location is currently unavailable";

  // const hasPickupETA =
  //   load.etas && load.etas.pickupEta && load.etas.pickupEta.eta;
  const isPickupETAHidden =
    !firstLoad.pickupStartedOn && !firstLoad.pickupArrivedOn;
  const pickupHiddenTooltip = isPickupETAHidden
    ? 'The ETA will be updated once driver is in route to pick-up.'
    : !firstLoad.pickupArrivedOn &&
      (timestampDiff > 10 ||
        !driverPosition ||
        !(firstLoad.etas && firstLoad.etas.pickupEta))
    ? noUpdateTooltip
    : '';

  // const hasDropoffETA =
  //   load.etas && load.etas.dropoffEta && load.etas.dropoffEta.eta;
  const isDropoffETAHidden =
    !lastLoad.dropoffStartedOn && !lastLoad.dropoffArrivedOn;
  const dropoffHiddenTooltip = isDropoffETAHidden
    ? 'The ETA will be updated once driver is in route to drop-off.'
    : !lastLoad.dropoffArrivedOn &&
      (timestampDiff > 10 ||
        !driverPosition ||
        !(lastLoad.etas && lastLoad.etas.dropoffEta))
    ? noUpdateTooltip
    : '';

  return (
    <div className={classes.container}>
      {(isDispatcher || isBroker) && (
        <LoadStatusTimelineItem
          date={firstLoad.loadStartedOn}
          isNextItemActive={!!firstLoad.pickupStartedOn}
          statusText="Accepted"
          hidden={!firstLoad.loadStartedOn}
        />
      )}
      <LoadStatusTimelineItem
        date={firstLoad.pickupStartedOn}
        isNextItemActive={!!firstLoad.pickupArrivedOn}
        prevDate={firstLoad.loadStartedOn}
        statusText="En Route to Pick-up"
        hidden={!firstLoad.pickupStartedOn}
      />
      <LoadStatusTimelineItem
        date={firstLoad.pickupArrivedOn}
        etaDate={
          firstLoad.etas &&
          firstLoad.etas.pickupEta &&
          firstLoad.etas.pickupEta.eta
        }
        isNextItemActive={!!firstLoad.dropoffStartedOn}
        statusText="Arrived at Pick-up"
        onTimeStatus={firstLoad.pickupOnTimeChipStatus}
        prevDate={firstLoad.loadStartedOn}
        window={firstLoad.pickupWindow}
        hidden={isPickupETAHidden}
        hiddenTooltip={pickupHiddenTooltip}
        extraRender={(active, textClassName) => (
          <>
            {firstLoad.pickupLocation && firstLoad.pickupLocation.isValid && (
              <Typography className={textClassName}>
                {firstLoad.pickupLocation.current.name}
              </Typography>
            )}
            {firstLoad.pickup && firstLoad.pickup.isValid && (
              <Typography className={textClassName}>
                {firstLoad.pickup.current.city},{' '}
                {firstLoad.pickup.current.state}{' '}
                {firstLoad.pickup.current.zipCode}
              </Typography>
            )}
          </>
        )}
      />
      {/***<LoadStatusTimelineItem
        date={load.pickupCheckedinOn}
        isNextItemActive={!!load.pickupDepartedOn}
        prevDate={load.loadStartedOn}
        statusText="Checked In at Pick-up"
      />***/}
      <LoadStatusTimelineItem
        date={firstLoad.dropoffStartedOn}
        isNextItemActive={!!lastLoad.dropoffArrivedOn}
        prevDate={firstLoad.loadStartedOn}
        statusText="En Route to Drop-off"
        hidden={!firstLoad.dropoffStartedOn}
      />
      <LoadStatusTimelineItem
        date={lastLoad.dropoffArrivedOn}
        etaDate={
          lastLoad.etas &&
          lastLoad.etas.dropoffEta &&
          lastLoad.etas.dropoffEta.eta
        }
        isNextItemActive={!!lastLoad.dropoffCompletedOn}
        hidden={isDropoffETAHidden}
        hiddenTooltip={dropoffHiddenTooltip}
        prevDate={lastLoad.loadStartedOn}
        statusText="Arrived at Drop-off"
        onTimeStatus={lastLoad.dropoffOnTimeChipStatus}
        window={lastLoad.dropoffWindow}
        extraRender={(active, textClassName) => (
          <>
            {lastLoad.dropoffLocation && lastLoad.dropoffLocation.isValid && (
              <Typography className={textClassName}>
                {lastLoad.dropoffLocation.current.name}
              </Typography>
            )}
            {lastLoad.dropoff && lastLoad.dropoff.isValid && (
              <Typography className={textClassName}>
                {lastLoad.dropoff.current.city},{' '}
                {lastLoad.dropoff.current.state}{' '}
                {lastLoad.dropoff.current.zipCode}
              </Typography>
            )}
          </>
        )}
      />
      {/***<LoadStatusTimelineItem
        date={load.dropoffCheckedinOn}
        isNextItemActive={!!load.dropoffCompletedOn}
        prevDate={load.loadStartedOn}
        statusText="Checked In at Drop-off"
      />***/}
      <LoadStatusTimelineItem
        date={lastLoad.dropoffCompletedOn}
        isLastItem
        prevDate={lastLoad.loadStartedOn}
        statusText="Delivered"
        hidden={!lastLoad.dropoffCompletedOn}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: 24,
    paddingRight: 20,
    paddingBottom: 24,
    paddingLeft: 20,
  },
}));

export default observer(LoadStatusTimelineMultiLeg);
