import { Button, Container, makeStyles } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {
  LOAD_STATUS,
  LOAD_STOP_TYPE,
  ON_TIME_STATUS,
} from 'doc-mate-store/lib/constants/load';
import { useStores } from 'doc-mate-store/lib/hooks';
import { LoadStop } from 'doc-mate-store/lib/models';
import { GPSPositionData, LatLng } from 'doc-mate-store/lib/models/GPSPosition';
import { OrderLeg, OrderLegData } from 'doc-mate-store/lib/models/OrderLeg';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router';
import CountdownTimer from '../../components/CountdownTimer';
import DriverPath from '../../components/DriverPath';
import LoadAppBar from '../../components/LoadAppBar';
import LoadStatusAppBar from '../../components/LoadStatusAppBar';
import RefreshNow from '../../components/RefreshNow';
import RouteMap from '../../components/RouteMap';
import TrackerAppBar from '../../components/TrackerAppBar';
import TrackerLoadDetail from '../../components/TrackerLoadDetail';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import TrackerLoadDetailMultiLeg from '../../components/TrackerLoadDetail/multiLeg';
import Root from '../Root';
type Props = {};

export type LoadStopWithFlag = { loadStop: LoadStop; isPickupDropff?: boolean };

const LOAD_REFRESH_INTERVAL = 60;

const Track: React.FC<Props> = () => {
  const { rootStore } = useStores();
  // @ts-ignore jus: might need to upgrade to react-router v6
  const { orderLegLinkId } = useParams();
  const classes = useStyles();
  const [path, setPath] = useState<GPSPositionData[]>([]);
  const leg = rootStore.trackedLeg;

  const [currentLeg, setCurrentLeg] = useState<OrderLeg | undefined>(leg);

  const [loadStatus, setLoadStatus] = useState<OrderLegData | null | undefined>(
    undefined,
  );

  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const showAll = location.search.includes('show_all');

  const [orderLegData, setOrderLegData] = useState<OrderLegData[] | null>([]);
  const { me } = rootStore;
  const user = me ? me.maybeCurrent : undefined;
  const isDispatcher = user && user.isDispatcher;
  const isBroker = user && user.isBroker;
  const legStatus = leg ? leg.status : LOAD_STATUS.UNASSIGNED;
  const isDispatcherOrBroker = isDispatcher || isBroker;

  const allLegs = rootStore.trackedAllLegs
    .sort((a, b) => Number(a.legNumber) - Number(b.legNumber))
    .sort((a, b) => Number(a.order.id) - Number(b.order.id));

  const currentActiveLeg = allLegs.find(
    leg =>
      leg.status !== LOAD_STATUS.DELIVERED &&
      leg.status !== LOAD_STATUS.COMPLETED,
  );

  const legStops = useMemo(() => {
    let stops: LoadStopWithFlag[] = [];
    if (
      !isDispatcherOrBroker &&
      leg?.isMultiOrder &&
      !showAll &&
      !leg.isMultiLeg
    ) {
      if (leg.order && leg.order.isValid && !!leg.order.current.orderStops) {
        const legStops = Array.from(leg.order.current.orderStops.values());
        for (const legStop of legStops) {
          stops.push({
            loadStop: legStop,
            isPickupDropff: false,
          });
        }
      }
      return [...new Set(stops)];
    }

    if (allLegs && allLegs.length > 0) {
      let updatedLegs = allLegs;

      if (currentLeg?.isMultiLeg && !isDispatcherOrBroker) {
        updatedLegs = allLegs.filter(
          leg => leg.order.id === currentLeg.order.id,
        );
      }

      for (const orderLeg of updatedLegs) {
        if (orderLeg.pickup.isValid) {
          const existingStop = stops.find(
            stop =>
              stop.loadStop.location.id === orderLeg.pickup.current.location.id,
          );

          if (existingStop) {
            const index = stops.findIndex(
              stop =>
                stop.loadStop.location.id === existingStop.loadStop.location.id,
            );

            stops[index] = {
              ...stops[index],
              isPickupDropff: existingStop.isPickupDropff
                ? true
                : existingStop.loadStop.stopType === LOAD_STOP_TYPE.DROPOFF ||
                  (leg?.isMultiLeg &&
                    existingStop.loadStop.stopType === LOAD_STOP_TYPE.PICKUP)
                ? true
                : false,
            };
          } else {
            const existingLocation = stops.find(
              stop =>
                stop.loadStop.location.isValid &&
                orderLeg.pickup.current.location.isValid &&
                stop.loadStop.location.current.id ===
                  orderLeg.pickup.current.location.current.id,
            );
            if (existingLocation) {
              if (
                existingLocation.loadStop.stopType !== LOAD_STOP_TYPE.PICKUP
              ) {
                stops.push({
                  loadStop: orderLeg.pickup.current,
                  isPickupDropff: false,
                });
              }
            } else {
              stops.push({
                loadStop: orderLeg.pickup.current,
                isPickupDropff: false,
              });
            }
          }
        }

        if (orderLeg.dropoff.isValid) {
          const existingStop = stops.find(
            stop =>
              stop.loadStop.location.id ===
              orderLeg.dropoff.current.location.id,
          );
          if (existingStop) {
            const index = stops.findIndex(
              stop =>
                stop.loadStop.location.id === existingStop.loadStop.location.id,
            );

            stops[index] = {
              ...stops[index],
              isPickupDropff: existingStop.isPickupDropff
                ? true
                : existingStop.loadStop.stopType === LOAD_STOP_TYPE.PICKUP ||
                  (leg?.isMultiLeg &&
                    existingStop.loadStop.stopType === LOAD_STOP_TYPE.DROPOFF)
                ? true
                : false,
            };
          } else {
            const existingLocation = stops.find(
              stop =>
                stop.loadStop.location.isValid &&
                orderLeg.dropoff.current.location.isValid &&
                stop.loadStop.location.current.id ===
                  orderLeg.dropoff.current.location.current.id,
            );
            if (existingLocation) {
              if (
                existingLocation.loadStop.stopType !== LOAD_STOP_TYPE.DROPOFF
              ) {
                stops.push({
                  loadStop: orderLeg.dropoff.current,
                  isPickupDropff: false,
                });
              }
            } else {
              stops.push({
                loadStop: orderLeg.dropoff.current,
                isPickupDropff: false,
              });
            }
          }
        }
      }
    }

    if (!isDispatcherOrBroker && leg?.isMultiLeg) {
      return stops.filter(
        stop =>
          stop.loadStop.stopNumber === 1 ||
          stop.loadStop.stopNumber === stops.length,
      );
    }

    return [...new Set(stops)];
  }, [
    allLegs,
    currentLeg?.isMultiLeg,
    currentLeg?.order.id,
    isDispatcherOrBroker,
    leg?.isMultiLeg,
    leg?.isMultiOrder,
    leg?.order,
    showAll,
  ]);

  useEffect(() => {
    (async () => {
      setLoading(true);

      if (orderLegLinkId) {
        const result = await rootStore.fetchOrderLegByOrderLegLinkId(
          orderLegLinkId,
        );

        setLoadStatus(result);

        const orderLegs = await rootStore.fetchAllOrderLegByOrderLegLinkId(
          orderLegLinkId,
        );

        setOrderLegData(orderLegs);
      }
      setLoading(false);
    })();
    return () => {
      rootStore.resetOrderLegLinkId();
    };
  }, [rootStore, orderLegLinkId, showAll, isDispatcherOrBroker]);

  useEffect(() => {
    if (!currentLeg) {
      setCurrentLeg(leg);
    }
  }, [currentLeg, leg]);

  useEffect(() => {
    if (leg) {
      leg.scheduleUpdateETA();
    }

    return () => {
      if (leg) {
        leg.clearUpdateETA();
      }
    };
  }, [currentLeg, leg, legStatus, rootStore]);

  const driverPosition = path.length > 0 ? path[path.length - 1] : undefined;

  if (driverPosition && currentActiveLeg) {
    currentActiveLeg.setCurrentGPSPosition(driverPosition);
  }

  const route = useMemo(() => {
    const result: LatLng[] = [];
    if (!leg) {
      return [];
    }
    if (driverPosition) {
      result.push({
        latitude: driverPosition.latitude,
        longitude: driverPosition.longitude,
      });
    }

    legStops.forEach(stop => {
      result.push(stop.loadStop.latlng);
    });
    return result;
  }, [driverPosition, leg, legStops]);

  const handleRefreshLoad = useCallback(() => {
    if (currentLeg) {
      if (currentLeg.isMultiLeg && !isDispatcherOrBroker) {
        rootStore.fetchAllOrderLegByOrderLegLinkId(currentLeg.orderLegLinkId);
      } else {
        rootStore.fetchOrderLegByOrderLegLinkId(currentLeg.orderLegLinkId);
      }
    }
  }, [currentLeg, isDispatcherOrBroker, rootStore]);

  const handlePathInsert = useCallback((additionalPath: GPSPositionData[]) => {
    setPath(prevPath => [...prevPath, ...additionalPath]);
  }, []);

  const handlePathReset = useCallback((newPath: GPSPositionData[]) => {
    setPath(newPath);
  }, []);

  useEffect(() => {
    if (leg) {
      handleRefreshLoad();
    }
  }, [handleRefreshLoad, leg]);

  if (
    !leg ||
    !rootStore.loaded ||
    typeof loadStatus === 'undefined' ||
    (leg &&
      (!leg.pickup ||
        !leg.pickup.isValid ||
        !leg.dropoff ||
        !leg.dropoff.isValid))
  ) {
    return (
      <Root>
        <Helmet>
          <title>
            {`Doc-Mate `}
            {isDispatcher ? 'Dispatcher' : isBroker ? 'Brokerage' : 'Tracker'}
          </title>
          <meta name="apple-itunes-app" content="app-id=1490860416" />
        </Helmet>
        <TrackerAppBar subtitle="Tracker" me={user} />
        <Container>Loading&hellip;</Container>
      </Root>
    );
  }

  const renderTopBar = () => {
    if (!user) {
      return null;
    }
    return <TrackerAppBar subtitle="Tracker" me={user} />;
  };

  const renderMapAreaBar = () => {
    if (user && currentLeg) {
      return <LoadAppBar loadId={currentLeg.dmId} showBackButton />;
    }
    return (
      <TrackerAppBar subtitle="Tracker" me={user}>
        {currentLeg && <LoadAppBar loadId={currentLeg.baseDmId} flex={2} />}
      </TrackerAppBar>
    );
  };

  const renderDispatcherLoadSelect = () => {
    if (isDispatcherOrBroker || (showAll && leg.isMultiOrder)) {
      return (
        <>
          <div
            style={{
              textAlign: 'center',
              paddingTop: 10,
            }}
          >
            <span
              style={{
                fontSize: 24,
                fontWeight: 600,
                justifySelf: 'center',
                alignItems: 'center',
                color: '#101828',
              }}
            >
              MANIFEST # {leg.baseDmId}
            </span>
          </div>
          <div
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 4,
            }}
          >
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              {!loading &&
                allLegs.map((leg, index) => (
                  <Button
                    key={leg.dmId}
                    variant={
                      leg.dmId === currentLeg?.dmId ? 'contained' : 'outlined'
                    }
                    onClick={() => {
                      setCurrentLeg(leg);
                      if (showAll) {
                        window.history.replaceState(
                          null,
                          '',
                          `/l/${leg.orderLegLinkId}/?show_all=true`,
                        );
                      } else {
                        window.history.replaceState(
                          null,
                          '',
                          `/l/${leg.orderLegLinkId}/`,
                        );
                      }
                    }}
                  >
                    {`${legStops.findIndex(
                      load =>
                        load.loadStop.location.id ===
                        leg.pickup.current.location.id,
                    ) + 1}`}
                    <ArrowRightAltIcon />
                    {`${legStops.findIndex(
                      load =>
                        load.loadStop.location.id ===
                        leg.dropoff.current.location.id,
                    ) + 1}`}
                  </Button>
                ))}
            </ButtonGroup>
          </div>
        </>
      );
    } else {
      <></>;
    }
  };

  const renderStatusAppBar = () => {
    if (currentLeg) {
      if (isDispatcherOrBroker || !currentLeg.isMultiLeg) {
        return (
          <LoadStatusAppBar
            sticky
            loadStatus={currentLeg.displayStatus}
            onTimeStatus={currentLeg.onTimeStatus}
          />
        );
      } else {
        const sameOrderLegs = allLegs.filter(
          leg => leg.order.id === currentLeg.order.id,
        );

        const firstLeg = sameOrderLegs.find(leg => leg.legNumber === 1);

        const lastLeg = sameOrderLegs.find(
          leg => leg.legNumber === sameOrderLegs.length,
        );

        if (
          firstLeg &&
          (firstLeg.displayStatus === 'Unassigned' ||
            firstLeg.displayStatus === 'Pending Acceptance' ||
            firstLeg.displayStatus === 'Accepted' ||
            firstLeg.displayStatus === 'Load Started' ||
            firstLeg.displayStatus === 'Pickup accepted' ||
            firstLeg.displayStatus === 'En Route to Pickup' ||
            firstLeg.displayStatus === 'Arrived at Pickup' ||
            firstLeg.displayStatus === 'Checked in at Pickup' ||
            firstLeg.displayStatus === 'Departed from Pickup' ||
            firstLeg.displayStatus === 'En Route to Dropoff')
        ) {
          return (
            <LoadStatusAppBar
              sticky
              loadStatus={firstLeg.displayStatus}
              onTimeStatus={firstLeg.onTimeStatus}
            />
          );
        } else if (
          lastLeg &&
          (lastLeg.displayStatus === 'En Route to Dropoff' ||
            lastLeg.displayStatus === 'Arrived at Dropoff' ||
            lastLeg.displayStatus === 'Delivered' ||
            lastLeg.displayStatus === 'Completed')
        ) {
          return (
            <LoadStatusAppBar
              sticky
              loadStatus={lastLeg.displayStatus}
              onTimeStatus={lastLeg.onTimeStatus}
            />
          );
        } else {
          return (
            <LoadStatusAppBar
              sticky
              loadStatus={'En Route to Drop-Off'}
              onTimeStatus={ON_TIME_STATUS.UNKNOWN}
            />
          );
        }
      }
    }
  };

  const handleTrackerLoadDetail = () => {
    if (currentLeg) {
      if (isDispatcherOrBroker || !currentLeg.isMultiLeg) {
        return <TrackerLoadDetail load={currentLeg} />;
      } else {
        if (!orderLegData) return;

        const sameOrderLegs = allLegs.filter(
          leg => leg.order.id === currentLeg.order.id,
        );
        const firstLeg = sameOrderLegs.find(leg => leg.legNumber === 1);
        const lastLeg = allLegs.find(
          leg => leg.legNumber === sameOrderLegs.length,
        );

        if (firstLeg && lastLeg) {
          return (
            <TrackerLoadDetailMultiLeg
              firstLoad={firstLeg}
              lastLoad={lastLeg}
            />
          );
        }
      }
    }
  };
  const renderLoadArea = () => {
    return (
      <div className={classes.loadArea}>
        {renderDispatcherLoadSelect()}
        {renderStatusAppBar()}
        {user ? (
          <RefreshNow onRefresh={handleRefreshLoad} />
        ) : (
          <CountdownTimer
            defaultTime={LOAD_REFRESH_INTERVAL}
            onTimeElapsed={handleRefreshLoad}
          />
        )}
        {handleTrackerLoadDetail()}
      </div>
    );
  };

  return (
    <Root>
      <Helmet>
        <title>
          {`Manifest # ${leg.baseDmId} | Doc-Mate `}
          {isDispatcher ? 'Dispatcher' : isBroker ? 'Brokerage' : 'Tracker'}
        </title>
      </Helmet>

      <div className={classes.container}>
        {renderTopBar()}
        <div
          className={[
            classes.content,
            user ? classes.contentLoggedIn : undefined,
          ].join(' ')}
        >
          <div className={classes.mapArea}>
            {renderMapAreaBar()}
            <DriverPath
              loads={allLegs}
              onPathInsert={handlePathInsert}
              onPathReset={handlePathReset}
              legLinkId={leg.orderLegLinkId}
              onlyShowLinkId={
                leg.isMultiOrder && !showAll && !isDispatcherOrBroker
              }
            />

            {!loading && currentLeg && (
              <RouteMap
                className={classes.map}
                driverPosition={driverPosition}
                driverPath={path}
                stops={legStops}
                route={route}
                showRoute={isBroker || isDispatcher}
                selectedStop1={
                  currentLeg
                    ? currentLeg.isMultiLeg &&
                      !isDispatcherOrBroker &&
                      !!legStops.length
                      ? legStops[0].loadStop.location.id
                      : currentLeg.pickup.current.location.id
                    : ''
                }
                selectedStop2={
                  currentLeg
                    ? currentLeg.isMultiLeg &&
                      !isDispatcherOrBroker &&
                      legStops.length > 1
                      ? legStops[1].loadStop.location.id
                      : currentLeg.dropoff.current.location.id
                    : ''
                }
              />
            )}
          </div>
          {renderLoadArea()}
        </div>
      </div>
    </Root>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '100%',
    },
  },
  content: {
    flexGrow: 1,
    height: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  contentLoggedIn: {
    height: 'calc(100% - 48px)',
    marginTop: theme.spacing(6),
    '& $mapArea': {
      height: 'calc(80% - 48px)',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      '& $mapArea': {
        height: '100%',
      },
    },
  },
  loadArea: {
    background: 'white',
    position: 'absolute',
    top: '80%',
    left: 0,
    right: 0,
    [theme.breakpoints.up('md')]: {
      flex: 1,
      height: '100%',
      overflow: 'auto',
      maxWidth: 480,
      minWidth: 480,
      position: 'static',
    },
  },
  loadDetailArea: {
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      flex: 1,
    },
  },
  mapArea: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    height: '80%',
    width: '100%',
    position: 'fixed',
    [theme.breakpoints.up('md')]: {
      position: 'static',
      height: '100%',
    },
  },
  map: {
    paddingTop: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
    },
  },
  refresh: {
    background: theme.palette.grey[100],
    margin: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
}));

export default observer(Track);
