import { Button, makeStyles } from '@material-ui/core';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import PriorityHigh from '@material-ui/icons/PriorityHigh';
import { Document, Image as PDFImage, Page, pdf } from '@react-pdf/renderer';
import { DOCUMENT_TYPE } from 'doc-mate-store/lib/constants/loadDocument';
import { useStores } from 'doc-mate-store/lib/hooks';
import {
  LoadDocument as LDocument,
  LoadDocumentFile,
} from 'doc-mate-store/lib/models/LoadDocument';
import { OrderLeg } from 'doc-mate-store/lib/models/OrderLeg';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useRef } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Labeled from '../Labeled';
import LoadDocumentPhotoList from '../LoadDocumentPhotoList';
type Props = {
  load: OrderLeg;
};

const LoadDocument: React.FC<Props> = ({ load }) => {
  const classes = useStyles();

  const { rootStore } = useStores();
  const { me } = rootStore;
  const user = me ? me.maybeCurrent : undefined;
  const isDispatcherOrBroker = !!(user && (user.isDispatcher || user.isBroker));

  // File Input
  const fileInputRefBOL = useRef<HTMLInputElement | null>(null);
  const fileInputRefTripSheet = useRef<HTMLInputElement | null>(null);
  const fileInputRefPU = useRef<HTMLInputElement | null>(null);
  const fileInputRefDO = useRef<HTMLInputElement | null>(null);
  const fileInputRefPUDamages = useRef<HTMLInputElement | null>(null);
  const fileInputRefDODamages = useRef<HTMLInputElement | null>(null);

  // Documents
  const billOfLoadingDocument = load.getDocument(DOCUMENT_TYPE.BILL_OF_LADING);
  const tripSheetDocument = load.getDocument(DOCUMENT_TYPE.TRIP_SHEET);
  const pickUpDocument = load.getDocument(DOCUMENT_TYPE.PICKUP_LOAD);
  const dropOffDocument = load.getDocument(DOCUMENT_TYPE.DROPOFF_LOAD);
  const pickUpDocumentDamages = load.getDocument(DOCUMENT_TYPE.PICKUP_DAMAGES);
  const dropOffDocumentDamages = load.getDocument(
    DOCUMENT_TYPE.DROPOFF_DAMAGES,
  );

  // Photos
  const photosBillOfLading = load.getDocumentForCarousel(
    DOCUMENT_TYPE.BILL_OF_LADING,
  );
  const photosTripSheet = load.getDocumentForCarousel(DOCUMENT_TYPE.TRIP_SHEET);
  const photosPickupLoad = load.getDocumentForCarousel(
    DOCUMENT_TYPE.PICKUP_LOAD,
  );
  const photosPickupDamages = load.getDocumentForCarousel(
    DOCUMENT_TYPE.PICKUP_DAMAGES,
  );
  const photosDropoffLoad = load.getDocumentForCarousel(
    DOCUMENT_TYPE.DROPOFF_LOAD,
  );
  const photosDropoffDamages = load.getDocumentForCarousel(
    DOCUMENT_TYPE.DROPOFF_DAMAGES,
  );

  //Files
  const filesBillOfLading = load.getDocumentForFiles(
    DOCUMENT_TYPE.BILL_OF_LADING,
  );
  const filesTripSheet = load.getDocumentForFiles(DOCUMENT_TYPE.TRIP_SHEET);
  const filesPickupLoad = load.getDocumentForFiles(DOCUMENT_TYPE.PICKUP_LOAD);
  const filesPickupDamages = load.getDocumentForFiles(
    DOCUMENT_TYPE.PICKUP_DAMAGES,
  );
  const filesDropoffLoad = load.getDocumentForFiles(DOCUMENT_TYPE.DROPOFF_LOAD);
  const filesDropoffDamages = load.getDocumentForFiles(
    DOCUMENT_TYPE.DROPOFF_DAMAGES,
  );

  const handleFileChangeBOL = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (
        !e.target.files ||
        e.target.files.length === 0 ||
        !billOfLoadingDocument
      ) {
        return;
      }

      if (
        e.target.files[0].type === 'image/jpeg' ||
        e.target.files[0].type === 'image/png'
      ) {
        await billOfLoadingDocument.addPhotos(e.target.files);
      } else {
        await billOfLoadingDocument.addFiles(e.target.files);
      }
    },
    [billOfLoadingDocument],
  );

  const handleFileChangeTripSheet = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (
        !e.target.files ||
        e.target.files.length === 0 ||
        !tripSheetDocument
      ) {
        return;
      }

      if (
        e.target.files[0].type === 'image/jpeg' ||
        e.target.files[0].type === 'image/png'
      ) {
        await tripSheetDocument.addPhotos(e.target.files);
      } else {
        await tripSheetDocument.addFiles(e.target.files);
      }
    },
    [tripSheetDocument],
  );
  const handleFileChangePU = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files || e.target.files.length === 0 || !pickUpDocument) {
        return;
      }

      if (
        e.target.files[0].type === 'image/jpeg' ||
        e.target.files[0].type === 'image/png'
      ) {
        await pickUpDocument.addPhotos(e.target.files);
      } else {
        await pickUpDocument.addFiles(e.target.files);
      }
    },
    [pickUpDocument],
  );

  const handleFileChangeDO = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files || e.target.files.length === 0 || !dropOffDocument) {
        return;
      }

      if (
        e.target.files[0].type === 'image/jpeg' ||
        e.target.files[0].type === 'image/png'
      ) {
        await dropOffDocument.addPhotos(e.target.files);
      } else {
        await dropOffDocument.addFiles(e.target.files);
      }
    },
    [dropOffDocument],
  );

  const handleFileChangePUDamages = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (
        !e.target.files ||
        e.target.files.length === 0 ||
        !pickUpDocumentDamages
      ) {
        return;
      }

      if (
        e.target.files[0].type === 'image/jpeg' ||
        e.target.files[0].type === 'image/png'
      ) {
        await pickUpDocumentDamages.addPhotos(e.target.files);
      } else {
        await pickUpDocumentDamages.addFiles(e.target.files);
      }
    },
    [pickUpDocumentDamages],
  );

  const handleFileChangeDODamages = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (
        !e.target.files ||
        e.target.files.length === 0 ||
        !dropOffDocumentDamages
      ) {
        return;
      }

      if (
        e.target.files[0].type === 'image/jpeg' ||
        e.target.files[0].type === 'image/png'
      ) {
        await dropOffDocumentDamages.addPhotos(e.target.files);
      } else {
        await dropOffDocumentDamages.addFiles(e.target.files);
      }
    },
    [dropOffDocumentDamages],
  );

  const resetFileInput = useCallback(
    (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      const inputElement = e.target as HTMLInputElement;

      inputElement.value = '';
    },
    [],
  );

  const handleDownloadPhotos = useCallback(async (photos: any) => {
    const PDFPhotos = () => (
      <Document>
        {photos.map((photo: any) => (
          <Page
            key={photo.id}
            style={{ padding: 10 }}
            orientation={photo.width > photo.height ? 'landscape' : 'portrait'}
          >
            <PDFImage src={photo.src} />
          </Page>
        ))}
      </Document>
    );

    const blob = await pdf(<PDFPhotos />).toBlob();
    return blob;
  }, []);

  const handleDownloadAll = useCallback(
    async (name: string, photos: any, files: LoadDocumentFile[]) => {
      const zip = new JSZip();
      const zipFileName = `${load.dmId} - ${name}`;

      const photosBlob = await handleDownloadPhotos(photos);
      if (files.length <= 0) {
        saveAs(photosBlob, `${zipFileName} - Photos.pdf`);
        return;
      }

      zip.file(`${zipFileName} - Photos.pdf`, photosBlob, {
        binary: true,
      });
      let file: LoadDocumentFile;
      for (file of files) {
        const filename = file.name as string;
        const fileBlob = await fetch(file.file);

        if (fileBlob) {
          const data = await fileBlob.blob();
          zip.file(filename, data, {
            binary: true,
          });
        }
      }
      zip.generateAsync({ type: 'blob' }).then(zipFile => {
        saveAs(zipFile, `${zipFileName}.zip`);
      });
    },
    [handleDownloadPhotos, load.dmId],
  );

  const handleDelete = useCallback(
    async (document: LDocument, id: string | number) => {
      await document.deleteDocumentFile(id);
    },
    [],
  );

  const handleDeleteConfirm = useCallback(
    async (document: LDocument, id: string | number) => {
      confirmAlert({
        title: 'Confirm to Delete',
        message: 'Are you sure you want to delete this file?',
        buttons: [
          { label: 'Confirm', onClick: () => handleDelete(document, id) },
          {
            label: 'Cancel',
          },
        ],
        overlayClassName: 'react-confirm-alert-overlay-custom',
      });
    },
    [handleDelete],
  );

  if (
    photosBillOfLading.length +
      photosTripSheet.length +
      photosPickupLoad.length +
      photosPickupDamages.length +
      photosDropoffLoad.length +
      photosDropoffDamages.length +
      filesBillOfLading.length +
      filesPickupLoad.length +
      filesPickupDamages.length +
      filesDropoffLoad.length +
      filesDropoffDamages.length ===
    0
  ) {
    return (
      <div className={classes.loadDetailItem}>
        <Labeled label="Documents &amp; Photos">To be uploaded</Labeled>
      </div>
    );
  }

  return (
    <div className={classes.loadDetailItem}>
      <Labeled label="Documents &amp; Photos">
        {photosBillOfLading.length + filesBillOfLading.length > 0 && (
          <Labeled
            inner
            label="Bill of Lading"
            className={classes.photoList}
            showDownloadIcon={photosBillOfLading.length > 0}
            textClassName={classes.sectionText}
            onClick={() =>
              handleDownloadAll(
                'Bill Of Lading',
                photosBillOfLading,
                filesBillOfLading,
              )
            }
          >
            {photosBillOfLading.length > 0 && (
              <>
                <span className={classes.documentText}>PHOTOS:</span>
                <div className={classes.photoListWrapper}>
                  <LoadDocumentPhotoList
                    photos={photosBillOfLading}
                    caption="Bill of Lading Photos"
                    onAdd={() => fileInputRefBOL?.current?.click()}
                    document={billOfLoadingDocument}
                    canUpdate={isDispatcherOrBroker}
                  />
                </div>
              </>
            )}
            {filesBillOfLading.length > 0 && (
              <>
                <span className={classes.documentText}>Files:</span>
                <div className={classes.fileListWrapper}>
                  {filesBillOfLading.map(file => (
                    <div className={classes.fileWrapper}>
                      <a
                        key={file.id}
                        href={file.file}
                        className={classes.file}
                        download
                        target="_blank"
                        rel="noreferrer"
                      >
                        <PictureAsPdf className={classes.pdfIcon} />
                        <span className={classes.fileName}>{file.name}</span>
                      </a>
                      {isDispatcherOrBroker && (
                        <Button
                          className={classes.button}
                          onClick={() =>
                            handleDeleteConfirm(billOfLoadingDocument!, file.id)
                          }
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}
            <input
              ref={fileInputRefBOL}
              id="bol"
              type="file"
              style={{
                visibility: 'hidden',
              }}
              className="image-upload hidden"
              accept="image/png, image/jpeg, application/pdf"
              onClick={resetFileInput}
              onChange={handleFileChangeBOL}
            />
          </Labeled>
        )}
        {photosTripSheet.length + filesTripSheet.length > 0 && (
          <Labeled
            inner
            label="Trip Sheet"
            className={classes.photoList}
            showDownloadIcon={photosTripSheet.length > 0}
            textClassName={classes.sectionText}
            onClick={() =>
              handleDownloadAll('Trip Sheet', photosTripSheet, filesTripSheet)
            }
          >
            {photosTripSheet.length > 0 && (
              <>
                <span className={classes.documentText}>PHOTOS:</span>
                <div className={classes.photoListWrapper}>
                  <LoadDocumentPhotoList
                    photos={photosTripSheet}
                    caption="Trip Sheet Photos"
                    onAdd={() => fileInputRefTripSheet?.current?.click()}
                    document={tripSheetDocument}
                    canUpdate={isDispatcherOrBroker}
                  />
                </div>
              </>
            )}
            {filesTripSheet.length > 0 && (
              <>
                <span className={classes.documentText}>Files:</span>
                <div className={classes.fileListWrapper}>
                  {filesTripSheet.map(file => (
                    <div className={classes.fileWrapper}>
                      <a
                        key={file.id}
                        href={file.file}
                        className={classes.file}
                        download
                        target="_blank"
                        rel="noreferrer"
                      >
                        <PictureAsPdf className={classes.pdfIcon} />
                        <span className={classes.fileName}>{file.name}</span>
                      </a>
                      {isDispatcherOrBroker && (
                        <Button
                          className={classes.button}
                          onClick={() =>
                            handleDeleteConfirm(tripSheetDocument!, file.id)
                          }
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}
            <input
              ref={fileInputRefTripSheet}
              id="bol"
              type="file"
              style={{
                visibility: 'hidden',
              }}
              className="image-upload hidden"
              accept="image/png, image/jpeg, application/pdf"
              onClick={resetFileInput}
              onChange={handleFileChangeTripSheet}
            />
          </Labeled>
        )}
        {photosPickupLoad.length + filesPickupLoad.length > 0 && (
          <Labeled
            inner
            label="Load at Pick-up"
            className={classes.photoList}
            showDownloadIcon={photosPickupLoad.length > 0}
            textClassName={classes.sectionText}
            onClick={() =>
              handleDownloadAll(
                'Load at Pick-up',
                photosPickupLoad,
                filesPickupLoad,
              )
            }
          >
            {photosPickupLoad.length > 0 && (
              <>
                <span className={classes.documentText}>PHOTOS:</span>
                <div className={classes.photoListWrapper}>
                  <LoadDocumentPhotoList
                    photos={photosPickupLoad}
                    caption="Load at Pick-up"
                    onAdd={() => fileInputRefPU?.current?.click()}
                    document={pickUpDocument}
                    canUpdate={isDispatcherOrBroker}
                  />
                </div>
              </>
            )}

            {filesPickupLoad.length > 0 && (
              <>
                <span className={classes.documentText}>Files:</span>
                <div className={classes.fileListWrapper}>
                  {filesPickupLoad.map(file => (
                    <div className={classes.fileWrapper}>
                      <a
                        key={file.id}
                        href={file.file}
                        className={classes.file}
                        download
                        target="_blank"
                        rel="noreferrer"
                      >
                        <PictureAsPdf className={classes.pdfIcon} />
                        <span className={classes.fileName}>{file.name}</span>
                      </a>
                      {isDispatcherOrBroker && (
                        <Button
                          className={classes.button}
                          onClick={() =>
                            handleDeleteConfirm(pickUpDocument!, file.id)
                          }
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}

            <input
              ref={fileInputRefPU}
              id="pu"
              type="file"
              style={{
                visibility: 'hidden',
              }}
              className="image-upload hidden"
              accept="image/png, image/jpeg, application/pdf"
              onClick={resetFileInput}
              onChange={handleFileChangePU}
            />

            {!(load.pickupHasNoDamages || photosPickupDamages.length === 0) && (
              <div className={classes.damages}>
                <PriorityHigh className={classes.priorityHigh} />
                Driver noted damages, shortages or returns at pick-up
              </div>
            )}
          </Labeled>
        )}
        {photosPickupDamages.length + filesPickupDamages.length > 0 && (
          <Labeled
            inner
            label="Damages, Shortages &amp; Returns at Pick-up"
            className={classes.photoList}
            showDownloadIcon={photosPickupDamages.length > 0}
            textClassName={classes.sectionText}
            onClick={() =>
              handleDownloadAll(
                'Pick-up Damages',
                photosPickupDamages,
                filesPickupDamages,
              )
            }
          >
            {photosPickupDamages.length > 0 && (
              <>
                <span className={classes.documentText}>PHOTOS:</span>
                <div className={classes.photoListWrapper}>
                  <LoadDocumentPhotoList
                    photos={photosPickupDamages}
                    caption="Damages, Shortages &amp; Returns at Pick-up"
                    onAdd={() => fileInputRefPUDamages?.current?.click()}
                    document={pickUpDocumentDamages}
                    canUpdate={isDispatcherOrBroker}
                  />
                </div>
              </>
            )}

            {filesPickupDamages.length > 0 && (
              <>
                <span className={classes.documentText}>Files:</span>
                <div className={classes.fileListWrapper}>
                  {filesPickupDamages.map(file => (
                    <div className={classes.fileWrapper}>
                      <a
                        key={file.id}
                        href={file.file}
                        className={classes.file}
                        download
                        target="_blank"
                        rel="noreferrer"
                      >
                        <PictureAsPdf className={classes.pdfIcon} />
                        <span className={classes.fileName}>{file.name}</span>
                      </a>
                      {isDispatcherOrBroker && (
                        <Button
                          className={classes.button}
                          onClick={() =>
                            handleDeleteConfirm(pickUpDocumentDamages!, file.id)
                          }
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}

            <input
              ref={fileInputRefPUDamages}
              id="pudamages"
              type="file"
              style={{
                visibility: 'hidden',
              }}
              className="image-upload hidden"
              accept="image/png, image/jpeg, application/pdf"
              onClick={resetFileInput}
              onChange={handleFileChangePUDamages}
            />
          </Labeled>
        )}
        {photosDropoffLoad.length + filesDropoffLoad.length > 0 && (
          <Labeled
            inner
            label="Load at Drop-off"
            className={classes.photoList}
            showDownloadIcon={photosDropoffLoad.length > 0}
            textClassName={classes.sectionText}
            onClick={() =>
              handleDownloadAll(
                'Load at Drop-off',
                photosDropoffLoad,
                filesDropoffLoad,
              )
            }
          >
            {photosDropoffLoad.length > 0 && (
              <>
                <span className={classes.documentText}>PHOTOS:</span>
                <div className={classes.photoListWrapper}>
                  <LoadDocumentPhotoList
                    photos={photosDropoffLoad}
                    caption="Load at Drop-off"
                    onAdd={() => fileInputRefDO?.current?.click()}
                    document={dropOffDocument}
                    canUpdate={isDispatcherOrBroker}
                  />
                </div>
              </>
            )}

            {filesDropoffLoad.length > 0 && (
              <>
                <span className={classes.documentText}>Files:</span>
                <div className={classes.fileListWrapper}>
                  {filesDropoffLoad.map(file => (
                    <div className={classes.fileWrapper}>
                      <a
                        key={file.id}
                        href={file.file}
                        className={classes.file}
                        download
                        target="_blank"
                        rel="noreferrer"
                      >
                        <PictureAsPdf className={classes.pdfIcon} />
                        <span className={classes.fileName}>{file.name}</span>
                      </a>
                      {isDispatcherOrBroker && (
                        <Button
                          className={classes.button}
                          onClick={() =>
                            handleDeleteConfirm(dropOffDocument!, file.id)
                          }
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}

            <input
              ref={fileInputRefDO}
              id="do"
              type="file"
              style={{
                visibility: 'hidden',
              }}
              className="image-upload hidden"
              accept="image/png, image/jpeg, application/pdf"
              onClick={resetFileInput}
              onChange={handleFileChangeDO}
            />

            {!(
              load.dropoffHasNoDamages || photosDropoffDamages.length === 0
            ) && (
              <div className={classes.damages}>
                <PriorityHigh className={classes.priorityHigh} />
                Driver noted damages, shortages or returns at drop-off
              </div>
            )}
          </Labeled>
        )}
        {photosDropoffDamages.length + filesDropoffDamages.length > 0 && (
          <Labeled
            inner
            label="Damages, Shortages &amp; Returns at Drop-off"
            className={classes.photoList}
            showDownloadIcon={photosDropoffDamages.length > 0}
            textClassName={classes.sectionText}
            onClick={() =>
              handleDownloadAll(
                'Drop-off Damages',
                photosDropoffDamages,
                filesDropoffDamages,
              )
            }
          >
            {photosDropoffDamages.length > 0 && (
              <>
                <span className={classes.documentText}>PHOTOS:</span>
                <div className={classes.photoListWrapper}>
                  <LoadDocumentPhotoList
                    photos={photosDropoffDamages}
                    caption="Damages, Shortages &amp; Returns at Drop-off"
                    onAdd={() => fileInputRefDODamages?.current?.click()}
                    document={dropOffDocumentDamages}
                    canUpdate={isDispatcherOrBroker}
                  />
                </div>
              </>
            )}

            {filesDropoffDamages.length > 0 && (
              <>
                <span className={classes.documentText}>Files:</span>
                <div className={classes.fileListWrapper}>
                  {filesDropoffDamages.map(file => (
                    <div className={classes.fileWrapper}>
                      <a
                        key={file.id}
                        href={file.file}
                        className={classes.file}
                        download
                        target="_blank"
                        rel="noreferrer"
                      >
                        <PictureAsPdf className={classes.pdfIcon} />
                        <span className={classes.fileName}>{file.name}</span>
                      </a>
                      {isDispatcherOrBroker && (
                        <Button
                          className={classes.button}
                          onClick={() =>
                            handleDeleteConfirm(
                              dropOffDocumentDamages!,
                              file.id,
                            )
                          }
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}

            <input
              ref={fileInputRefDODamages}
              id="dodamages"
              type="file"
              style={{
                visibility: 'hidden',
              }}
              className="image-upload hidden"
              accept="image/png, image/jpeg, application/pdf"
              onClick={resetFileInput}
              onChange={handleFileChangeDODamages}
            />
          </Labeled>
        )}
      </Labeled>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  loadDetailItem: {
    alignItems: 'center',
    display: 'flex',
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    // fontWeight: theme.typography.fontWeightMedium,
    fontWeight: 500,
  },
  noDamages: {
    clear: 'both',
    fontSize: theme.typography.body2.fontSize,
    marginLeft: -theme.spacing(1.5),
  },
  documentText: {
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 2,
    textTransform: 'uppercase',
    marginLeft: 1,
  },
  fileListWrapper: {
    marginTop: 4,
  },
  photoListWrapper: {
    marginLeft: 8,
    marginTop: 4,
  },
  fileWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  file: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: 8,
    marginTop: 4,
    color: '#0d374b',
    textDecoration: 'none',
  },
  fileName: {
    fontSize: 11,
    textOverflow: 'hidden',
    marginLeft: 2,
    marginRight: 2,
  },
  pdfIcon: {
    width: 40,
    height: 40,
  },
  damages: {
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette.error.main,
    marginLeft: -theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  priorityHigh: {
    height: 20,
  },
  photoList: {
    marginBottom: theme.spacing(1),
    clear: 'both',
  },
  button: {
    padding: 0,
    fontWeight: 700,
    marginLeft: theme.spacing(0.5),
    background: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
  sectionText: {
    fontSize: 14,
    lineHeight: 2,
  },
}));

export default observer(LoadDocument);
