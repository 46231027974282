import { LOAD_STOP_TYPE } from 'doc-mate-store/lib/constants/load';
import React from 'react';

type Props = {
  lat: number;
  lng: number;
  stopType: LOAD_STOP_TYPE;
  isPickupDropoff?: boolean;
  isSelected?: boolean;
  stopNumber: number;
};

const typeToIcon = (
  type: LOAD_STOP_TYPE,
  isPickupDropoff: boolean,
  isSelected: boolean,
) => {
  if (isPickupDropoff) {
    return (
      <path
        d="M16.5 7.495H18L16 5.5L14 7.495H15.5V12.505H14L16 14.5L18 12.505H16.5V7.495Z"
        fill={isSelected ? 'white' : '#155878'}
      />
    );
  }

  if (type === LOAD_STOP_TYPE.DROPOFF) {
    return (
      <path
        d="M19 10L18.295 9.295L15.5 12.085V6H14.5V12.085L11.71 9.29L11 10L15 14L19 10Z"
        fill={isSelected ? 'white' : '#155878'}
      />
    );
  }

  if (type === LOAD_STOP_TYPE.PICKUP) {
    return (
      <path
        d="M11 10L11.705 10.705L14.5 7.915V14H15.5V7.915L18.29 10.71L19 10L15 6L11 10Z"
        fill={isSelected ? 'white' : '#155878'}
      />
    );
  }
};

const GenericMarker: React.FC<Props> = ({
  stopType,
  isSelected = true,
  isPickupDropoff = false,
  stopNumber,
}) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: -15,
        left: -15,
      }}
    >
      <svg
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill={isSelected ? '#155878' : 'white'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V13.5C21 14.8978 21 15.5967 20.7716 16.1481C20.4672 16.8831 19.8831 17.4672 19.1481 17.7716C18.5967 18 17.8978 18 16.5 18C16.0114 18 15.7671 18 15.5405 18.0535C15.2383 18.1248 14.9569 18.2656 14.7185 18.4645C14.5397 18.6137 14.3931 18.8091 14.1 19.2L12.64 21.1467C12.4229 21.4362 12.3143 21.5809 12.1812 21.6327C12.0647 21.678 11.9353 21.678 11.8188 21.6327C11.6857 21.5809 11.5771 21.4362 11.36 21.1467L9.9 19.2C9.60685 18.8091 9.46028 18.6137 9.2815 18.4645C9.04312 18.2656 8.76169 18.1248 8.45951 18.0535C8.23287 18 7.98858 18 7.5 18C6.10218 18 5.40326 18 4.85195 17.7716C4.11687 17.4672 3.53284 16.8831 3.22836 16.1481C3 15.5967 3 14.8978 3 13.5V7.8Z"
          stroke={isSelected ? 'white' : '#155878'}
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <text
          stroke={isSelected ? 'white' : '#155878'}
          fontSize="10"
          fontWeight="100"
        >
          <tspan x="5" y="13.3">
            {stopNumber}
          </tspan>
        </text>
        {typeToIcon(stopType, isPickupDropoff, isSelected)}
      </svg>
    </div>
  );
};

export default GenericMarker;
